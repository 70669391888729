<template>
  <div class="project-description">
    <div class="container-greeting">
      <div class="project-details__container-image-before mt-3">
        <v-btn @click="$router.go(-1)" x-small class="mt-0 mb-2" fab icon>
          <img width="15" src="../../assets/icon/navigate_before.png" />
        </v-btn>
      </div>
      <div>
        <h2 class="project-title mt-4 text-title-xl">
          {{ project.title.rendered }}
        </h2>
      </div>
    </div>

    <div>
      <img :src="project.logo" class="project-img" />
    </div>

    <div>
      <h3 class="text-left subtitle-project">{{ project.title.rendered }}</h3>
      <p class="project-txt">
        {{ project.descripcion }}
      </p>
      <v-form ref="form">
        <!-- (v) => v.length <= 2000 || 'supera el limite de caracteres', -->
        <v-textarea
          :rules="[(v) => !!v || 'Este campo es requerido']"
          class="mt-2 mb-5"
          label="Ingrese aquí la razón"
          counter="2000"
          rows="4"
          v-model="reason"
        ></v-textarea>
        <v-btn
          :loading="loadingAll"
          @click="save()"
          rounded
          block
          dark
          class="btn-main v-step-17"
        >
          <strong> Guardar </strong>
        </v-btn>
      </v-form>
    </div>

    <Alert
      :open="alert.open"
      :text="alert.txt"
      :title="alert.title"
      :type="alert.type"
      :redirect="alert.redirect"
      @close="alert.open = false"
    >
    </Alert>
  </div>
</template>
<script>
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    Alert,
  },
  data() {
    return {
      alert: {
        txt: "",
        title: "",
        type: "",
        open: false,
        redirect: "",
      },
      id: null,
      loadingAll: false,
      project: {},
      reason: "",
      data: {
        description: "",
        lifeprojectid: "",
        date: "",
        contractorid: "",
        userid: "",
        useremail: "",
        status: "true",
        fecha_registro: "",
        full_name: "",
        ciudad: "",
        celular: "",
        centro_costos: "",
        subcentro_costos: "",
        campana: "",
      },
      info: null,
      infoToken: {
        document: "",
      },
    };
  },
  methods: {
    getStatusControl() {
      this.loadingAll = true;
      Api.noAuth()
        .getStatusControl(this.infoToken.document)
        .then((resp) => resp.json())
        .then((data) => {
          console.log("***************");
          console.log(data);
        })
        .catch((error) => {
          if (error.response.cod == 1004) {
            console.log("Mostrar pilares");
          }
          console.log(error);
        });
    },
    getControl() {
      this.loadingAll = true;
      Api.noAuth()
        .getControl()
        .then((resp) => resp.json())
        .then((data) => {
          data.forEach((app) => {
            if (app.id == this.id) {
              this.project = app;
              this.loadingAll = false;
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getToken() {
      var token = localStorage.getItem("token");
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    getProfile() {
      Api.noAuth()
        .profile(this.infoToken.document)
        .then((res) => {
          this.info = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    save() {
      this.loadingAll = true;
      this.data.userid = this.infoToken.document;
      this.data.useremail = this.infoToken.emailPersonal;
      this.data.contractorid = sessionStorage.getItem("contractor");
      this.data.date = sessionStorage.getItem("dateAdmision");
      this.data.description = this.reason;
      this.data.lifeprojectid = this.$route.params.id;
      this.data.celular = sessionStorage.getItem("phone");
      this.data.ciudad = sessionStorage.getItem("city");
      this.data.full_name = sessionStorage.getItem("fullName");
      this.data.fecha_registro = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.data.centro_costos = this.info.cc;
      this.data.subcentro_costos = this.info.cod_scc;
      this.data.campana = this.info.campaign;

      if (!this.reason || this.reason.length === 0) {
        this.loadingAll = false;
        this.alert.open = true;
        this.alert.title = "¡Oh no!";
        this.alert.txt = "Debe ingresar una razón";
        this.alert.type = "error";
        this.alert.redirect = "";
      } else {
        Api.noAuth()
          .save(this.data)
          .then((resp) => resp.json())
          .then((data) => {
            this.loadingAll = false;
            if (data.cod == 0) {
              // Aca tengo que invocar la función
              this.getStatusControl(this.infoToken.document);
              this.alert.open = true;
              this.alert.title = "Excelente";
              this.alert.txt =
                " Pronto su padrino se comunicará contigo para hacer realidad su proyecto de vida";
              this.alert.type = "success";
              this.alert.redirect = "lifeControl";
              this.$refs.form.reset();

              // setTimeout(() => {
              //   this.$router.push({name: "lifeControl"});
              // }, 10000);
            } else if ((data.cod = 1001)) {
              this.alert.open = true;
              this.alert.title = "Para que sepas...";
              this.alert.txt = "Ya tiene un registro activo";
              this.alert.type = "info";
              this.alert.redirect = "";
              this.$refs.form.reset();
            } else {
              this.alert.open = true;
              this.alert.title = "¡Oh no!";
              this.alert.txt = "Intenta mas tarde";
              this.alert.type = "error";
              this.alert.redirect = "";
              this.$refs.form.reset();
            }
          })
          .catch((error) => {
            this.alert.open = true;
            this.alert.title = "¡Oh no!";
            this.alert.txt = "Intenta mas tarde";
            this.alert.type = "error";
            this.alert.redirect = "";
          });
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    getStatusControl(document) {
      let controlLife = {
        flag: false,
        projects: [],
        activeRequest: null,
      };
      Api.noAuth()
        .getStatusControl(document)
        .then((resp) => resp.json())
        .then((data) => {
          // if (!data.data) {
          if (data.data) {
            controlLife.activeRequest = data.data[0];
          }
          Api.noAuth()
            .getControl()
            .then((resp) => resp.json())
            .then((car) => {
              if (!data.data) {
                controlLife.flag = true;
              } else {
                controlLife.flag = false;
              }

              // controlLife.flag = true;
              car.forEach((app) => {
                controlLife.projects.push(app);
              });
            })
            .catch((error) => {
              console.log(error);
            });
          // }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.dispatch("updateStatusControl", controlLife);
        });
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getControl();
    this.getToken();
    this.getProfile();
    this.getStatusControl();
  },
};
</script>
<style scoped>
@import "./../../assets/css/main.less";
.project-description {
  margin: auto;
  margin-top: 10px;
  width: 90vw;
}

.project-title {
  color: #466be3;
  font-family: "ProximaNovaLight";
  font-size: 22px;
  font-weight: 400;
}

.navigate-before {
  width: 15px;
  height: 15px;
  display: flex;
  align-items: flex-start;
}

.container-greeting {
  display: flex;
}

.project-details__container-image-before {
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-img {
  width: 30%;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
}

.subtitle-project {
  color: #466be3;
  font-family: "AsapSemiBold";
  font-weight: 600;
  line-height: 25px;
  margin-bottom: 16px;
}

.project-txt {
  color: #898989;
  line-height: 20px;
  font-family: "ProximaNovaLight";
  text-align: left;
}

.request-project {
  color: #466be3;
  font-family: "AsapSemiBold" !important;
  font-weight: 700 !important;
  word-break: normal;
  text-align: center;
}

.request-txt-project {
  font-family: "ProximaNovaLight";
  color: #898989 !important;
  font-weight: 300;
}

.dialogMessage {
  padding-top: 30px !important;
  font-size: 25px;
  color: #466be3;
  font-family: "AsapSemiBold";
  font-weight: 700;
  text-align: center;
  word-break: break-word !important;
  line-height: 30px;
}

@media only screen and (min-width: 500px) {
  .project-description {
    width: 70vw;
  }

  .project-img {
    width: 110px;
  }
}

@media only screen and (min-width: 500px) {
  .controlLife__dialog {
    width: 60% !important;
  }
}
</style>
